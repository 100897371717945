<template>
    <div class="slider">
        <swiper
            :slides-per-view="slidePerView"
            :space-between="spaceBetween"
            :navigation="navigation"
            @slideChange="onSlideChange"
            :pagination="pagination"
            :modules="modules"
            :class="[responsive ? `swiper-responsive` : '']"
        >
            <template
                v-for="(slotName, index) in slots"
                :key="index"
            >
                <swiper-slide>
                    <slot :name="slotName" />
                </swiper-slide>
            </template>
        </swiper>
        <div class="swiper-stepper" v-if="stepper">
            {{ $t("{activeSlide} of {totalItems}", { activeSlide, totalItems: slots.length }) }}
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import SwiperCore, { Navigation, Pagination } from "swiper";

SwiperCore.use([Navigation]);

export default {
    props: {
        responsive: Boolean,
        stepper: Boolean,
        pagination: Boolean,
        navigation: Boolean,
        spaceBetween: Number
    },
    components: {
        Swiper,
        SwiperSlide
    },
    name: "Slider",
    data() {
        return {
            activeSlide: 1,
            slidePerView: "auto"
        };
    },
    methods: {
        onSlideChange(swiper) {
            this.activeSlide = swiper.activeIndex + 1;
        }
    },
    computed: {
        slots() {
            return Object.keys(this.$slots)
                .filter(i => i.startsWith("slide-"));
        }
    },
    setup() {
        return {
            modules: [Pagination],
        };
    },
};
</script>

<style lang="scss">
    @import "index";
</style>

